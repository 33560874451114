import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdationServiceService {
globalMenuName: string ="";
 store:any;
 triggerSource: any;
  constructor() { }
  private applyFilterGrid$:BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateRowValue$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateActionsCell$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateRowValueDSCR$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateRowValueNOI$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateRowValueDY$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateAgGridWithAllTriggers$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private updateAgGridWithAllCMBS$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private updateAgGridWithAllCLO$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private updateAgGridWithCustomTrigger$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateAgGridRowWithCustomTrigger$:BehaviorSubject<any> = new BehaviorSubject<any>("");
  private updateAgGridWithGlobalTriggers$:BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  applyFilterIntoGrid$= this.applyFilterGrid$.asObservable();
  selectedProduct$ = this.updateRowValue$.asObservable();
  selectedActionRow$ = this.updateActionsCell$.asObservable();
  selectedAllTriggers$ = this.updateAgGridWithAllTriggers$.asObservable();
  selectedAllCMBS$ = this.updateAgGridWithAllCMBS$.asObservable();
  selectedAllCLO$ = this.updateAgGridWithAllCLO$.asObservable();
  selectedDSCR$ = this.updateRowValueDSCR$.asObservable();
  selectedNOI$ = this.updateRowValueNOI$.asObservable();
  selectedDY$ = this.updateRowValueDY$.asObservable();
  selectedCustom$ = this.updateAgGridWithCustomTrigger$.asObservable();
  selectedCustomTrigger$= this.updateAgGridRowWithCustomTrigger$.asObservable();
  selectedGlobalTriggers$= this.updateAgGridWithGlobalTriggers$.asObservable();
  
  applyFilter(data : any){
    this.applyFilterGrid$.next(data);
  }
  setCustom(customRecords : any){
    this.updateAgGridWithCustomTrigger$.next(customRecords);
  }
  setCustomTrigger(customTriggerRecords : any){
    this.updateAgGridRowWithCustomTrigger$.next(customTriggerRecords);
  }
  updateActionsCell(product: any) {
    this.updateActionsCell$.next(product);
  }
  setProduct(product: any) {
    this.updateRowValue$.next(product);
  }
  setDSCR(product: any) {
    this.updateRowValueDSCR$.next(product);
  }
  setNOI(product: any) {
    this.updateRowValueNOI$.next(product);
  }
  setDY(product: any) {
    this.updateRowValueDY$.next(product);
  }
  resetAgGridForAllTrigger(triggers: any){
    this.updateAgGridWithAllTriggers$.next(triggers);
  }
  resetAgGridForAllCMBS(triggers: any){
    this.updateAgGridWithAllCMBS$.next(triggers);
  }
  resetAgGridForAllCLO(triggers: any){
    this.updateAgGridWithAllCLO$.next(triggers);
  }
  resetAgGridForGlobalTriggers(triggers: any){
    this.updateAgGridWithGlobalTriggers$.next(triggers);
  }
  startLoading() {
    this.loadingSubject.next(true);
  }
  stopLoading() {
    this.loadingSubject.next(false);
  }
}
