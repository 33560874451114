<div class="col-sm-12 history-top text-right">
    <mat-icon class="icon-size cursor-pointer" (click)="openDialog()">history</mat-icon>
</div>

<div class="container-fluid">
  <div class="text-center">
    <h1>Custom Trigger</h1>
  </div>
    <div class="container flex flex-wrap p-4 mx-auto">
        <label for="loan" class="col-sm-4 col-form-label">Loan:</label>
        <div class="col-sm-7">
          <input type="text" class="form-control" [(ngModel)]="userData.loanNumber">
      </div>
    </div>

    <div class="container flex flex-wrap p-4 mx-auto">
      <label for="notificationDate" class="col-sm-4 col-form-label">Notification Date:</label>
      <div class="col-sm-7">
        <mat-form-field appearance="fill" [style.width.px]=327>
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" name="date"
          [(ngModel)]="userData.triggerDateValue">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Choose a date</mat-label>
          <input class=" col-sm-7 form-control"  matInput [matDatepicker]="picker" name="date"
          ngDefaultControl [(ngModel)]="userData.triggerDateValue">
          <mat-datepicker-toggle matIconSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
    </div>
  </div>

  <div class="container flex flex-wrap p-4 mx-auto">
    <label for="lastFiredValue" class="col-sm-4 col-form-label">Description :</label>
    <div class="col-sm-7">
     <textarea class="form-control text-box-height" [(ngModel)]="userData.triggerDescription"
     [ngModelOptions]="{standalone: true}"></textarea>
  </div>
</div>

<div class="text-center">
  <button class="submitButton" mat-flat-button color="primary" #button
  [title]="button.disabled?'Kindly save the form to enable submit':'click to submit'"
  (click)="UpdateData()">Save Edit</button>
</div>

  
      

