import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TriggerHistoryDialogService } from './trigger-history-dialog.service';

@Component({
  selector: 'app-trigger-history-dialog',
  templateUrl: './trigger-history-dialog.component.html',
  styleUrls: ['./trigger-history-dialog.component.css']
})
export class TriggerHistoryDialogComponent implements OnInit {

  MyObjectClass: any;
  public triggerHistory: any;
  triggerData: any;
  DataArray: any;
  notes: any;
  reqObj: any;
  message: string = '';
  addHistory: any;
  constructor(
    public dialogRef: MatDialogRef<TriggerHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private historyService: TriggerHistoryDialogService
    ) { 
    }

    ngOnInit() {
      this.dialogRef.updateSize('40%', '90%');
      this.triggerData = this.data;
      this.DataArray = this.triggerData.data;
      console.log(this.DataArray);
      if(this.DataArray.length === 0) {
        this.message = 'No history found!';
      }
    }

    AddNote(notes: any) {
      console.log(this.triggerData);
      console.log(notes);
      this.reqObj = { 
        "triggerId": this.triggerData.data[0].triggerId,
        "eventType": this.triggerData.data[0].eventType,
        "eventDescription": notes
      }
      console.log(this.reqObj);
      this.addHistory= JSON.stringify(this.reqObj);
      this.historyService.addTriggerDescription(this.reqObj).subscribe((res: any) => {
        console.log(res);
        this.DataArray.unshift(res);
        console.log(this.DataArray);
      })
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
