import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UpdationServiceService } from 'src/app/services/updation-service.service';
import { TriggerTemplateService } from 'src/app/services/trigger-template.service';

@Component({
  selector: 'app-clo-template',
  templateUrl: './add-custom-trigger.component.html',
  styleUrls: ['./add-custom-trigger.component.css']
})
export class AddCustomTriggerComponent implements OnInit {
  //toastrService: any;
  @Input() set triggerID(id: any) {
    this.userData.triggerID = id;
  }
  @Input() set loanNumber(id: any) {
    this.userData.loanNumber = id;
  }
  @Input() set loanKey(id: any) {
    this.userData.loanKey = id;
  }
  @Output() closeSideNav = new EventEmitter();

  closeForm(): void {
    this.closeSideNav.emit();
  }

  userData = {
    loanKey: '',
    triggerID: '',
    triggerType: '',
    loanNumber: '',
    bloombergID: '',
    triggerValue: '',
    triggerDescription: '',
    triggerDateValue: '',
  };
  dataSet: any;
  constructor(private toastrService: ToastrService,
    private updationService: UpdationServiceService, private triggerTemplateService: TriggerTemplateService) {

  }


  ngOnInit(): void {
     this.userData.triggerDateValue ='';
     this.userData.triggerDescription ='';
  }

  ngOnChanges(): void {
    this.userData.triggerDateValue ='';
     this.userData.triggerDescription ='';
    if (this.userData.triggerID != null && this.userData.triggerID != "") {
      this.triggerTemplateService.getIndividualLoanData(this.userData.triggerID).subscribe((data: any) => {
        this.dataSet = data;
        this.userData = data;
      }),
        (error: any) => {
          console.log(error.status + ' - initial load error, reolved as entire data loads!')
        };
    }
  }
  public onDate(event: any): void {
    const newDate = event;
    this.userData.triggerDateValue = new Date(newDate).toISOString();
    console.log("notification date", this.userData.triggerDateValue);
  }

  public getTriggerDescription(event: any): void {
    this.userData.triggerDescription = event;
  }
  public onValueChange(event: Event): void {
    const value = (event.target as any).value;
    this.userData.triggerDescription = value;
  }

  showSuccess(): void {
    var x = this.userData;
    x.loanKey = x.loanKey.toString();
    this.toastrService.info('Saving changes...');
    this.triggerTemplateService.saveCustomTrigger(x).subscribe(
      (data) => {
        if(data!=null){
        this.toastrService.success('Custom Trigger Saved.');
        this.updationService.setCustom(data); 
        this.updationService.applyFilter(data);
        }
        else{
          this.toastrService.error('Error :: Custom Trigger not Saved.');
        }
      }
    );
  }

}
