import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UpdationServiceService } from 'src/app/services/updation-service.service';
import { TriggerHistoryDialogService } from '../trigger-history-dialog/trigger-history-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TriggerHistoryDialogComponent } from '../trigger-history-dialog/trigger-history-dialog.component';
import { TriggerTemplateService } from 'src/app/services/trigger-template.service';

@Component({
  selector: 'app-customtrigger-template',
  templateUrl: './customtrigger-template.component.html',
  styleUrls: ['./customtrigger-template.component.css']
})
export class CustomtriggerTemplateComponent implements OnInit {
  public content!: string;
  public parameters!: FormGroup;
  constructor(private toastrService: ToastrService, private _ngZone: NgZone, private http: HttpClient,
    private updationService: UpdationServiceService, public dialog: MatDialog, private historyService: TriggerHistoryDialogService,
    private triggerTemplateService: TriggerTemplateService
  ) {
    this.content = this.userData.triggerDescription;
  }

  @Input() set triggerID(id: any) {
    this.userData.triggerID = id;
  }
  @Input() set loanNumber(id: any) {
    this.userData.loanNumber = id;
  }
  @Input() set loanKey(id: any) {
    this.userData.loanKey = id;
  }
  @Output() closeSideNav = new EventEmitter();

  closeForm(): void {
    this.closeSideNav.emit();
  }
  userData = {
    loanKey: '',
    triggerID: '',
    triggerType: '',
    loanNumber: '',
    bloombergID: '',
    triggerValue: '',
    triggerDescription: '',
    triggerDateValue: '',
  };
  @Input() dataSet: any;
  date: Date | undefined;

  ngOnInit(): void {
    this.parameters = new FormGroup({
      'startDate': new FormControl(null, Validators.required),
    });
  }

  ngOnChanges(): void {
    this.userData.triggerDescription = this.dataSet.triggerDescription;
    this.userData.loanNumber = this.dataSet.poolNumber;
    this.userData.triggerDateValue = new Date(this.dataSet.triggerDateValue).toISOString();
  }

  UpdateData(): void {
    var x = this.userData;
    this.dataSet.triggerDateValue = x.triggerDateValue;
    this.dataSet.triggerDescription = x.triggerDescription;
    this.dataSet.poolNumber = x.loanNumber;
    this.dataSet.loanKey == x.loanKey;
    var finalDataSet = this.dataSet;
    this.toastrService.info('Saving changes...');
    this.triggerTemplateService.updateCustomTrigger(finalDataSet).subscribe(
      (data) => {
        if(data!=null){
        this.toastrService.success('Custom Trigger Updated.');
        this.updationService.setCustomTrigger(data); 
        console.log(data);
        }
        else{
          this.toastrService.error('Error :: Custom Trigger not Update.');
          console.log(data);
        }
      }
    );
  }

  openDialog(): void {
    this.historyService.getTriggerHistory(this.dataSet.id).subscribe((res: any) => {
      const triggerHistory = res.body;
      this.dialog.open(TriggerHistoryDialogComponent, {
        data: { data: triggerHistory }
      });
    })
  }

  public onDate(event: any) {
    this.dataSet.expirationDate = event;
  }
}


