<!-- <ng-container *ngIf="(isAuthenticated$ | async) === false;" >
  <button (click)="signIn()" > Sign in </button>
</ng-container> -->

<!-- <ng-template #signout>
  <button (click)="signOut()">Sign out</button>
</ng-template> -->
<!-- <div class="toolbar" role="banner">
 </div>
<app-navbar ></app-navbar>
<ng-container *ngIf="(isAuthenticated$ | async) === true;"><app-datatable></app-datatable></ng-container>
   -->
 <!-- <app-profile></app-profile> -->
<!-- <router-outlet></router-outlet> -->
<div>
  <!-- <a routerLink="/" class="header item">
  &nbsp;
  Okta-Angular Sample Project
</a> -->
<!-- <a  *ngIf="!(authStateService.authState$ | async)?.isAuthenticated" (click)="login()">Login</a> -->
<!-- <a *ngIf="(authStateService.authState$ | async)?.isAuthenticated" routerLink="/app-datatable"></a> -->
<!-- <a  *ngIf="(authStateService.authState$ | async)?.isAuthenticated" routerLink="/profile">Profile</a> -->
<!-- <a  *ngIf="(authStateService.authState$ | async)?.isAuthenticated" (click)="logout()">Logout</a> -->
</div>

<div>
  <router-outlet></router-outlet>
</div>