import { Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { CommonModule } from "@angular/common";
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HttpClientModule } from '@angular/common/http';
import { DatatableComponent } from './components/datatable/datatable.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantTemplateComponent } from './components/tenant-template/tenant-template.component';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TextareaComponent } from './components/textarea/textarea.component';
import { MatDividerModule } from '@angular/material/divider';
import { TextFieldModule } from '@angular/cdk/text-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import { OktaAuthGuard, OktaAuthModule, OktaCallbackComponent, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ProfileComponent } from './components/profile/profile.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { from } from 'rxjs';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule, Routes } from '@angular/router';
import { UniquePipe } from './pipes/unique-filter.pipe';
import { DscrTemplateComponent } from './components/dscr-template/dscr-template.component';
import { CustomTemplateComponent } from './components/custom-template/custom-template.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NoiTemplateComponent } from './components/noi-template/noi-template.component';
import { DyTemplateComponent } from './components/dy-template/dy-template.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ButtonclickComponent } from './components/buttonclick/buttonclick.component';
import { NameDPipe } from './pipes/name-d.pipe';
import { RemoveSpacesPipe } from './pipes/remove-spaces.pipe';
import { AddCustomTriggerComponent } from './components/add-custom-trigger/add-custom-trigger.component';
import { CustomtriggerTemplateComponent } from './components/customtrigger-template/customtrigger-template.component';
import { TriggerHistoryDialogComponent } from './components/trigger-history-dialog/trigger-history-dialog.component';
import { BtnCellRenderer } from './components/button-cell-renderer/button-cell-renderer';
import { SnoozeCellRenderer } from './components/snooze-cell-renderer/snooze-cell-renderer';
import { environment } from 'src/environments/environment';
import { NgDragDropModule } from 'ng-drag-drop';
import { percentagePipe } from './pipes/percentage.pipe';
import { OtherTemplateComponent } from './components/other-template/other-template.component';

const oktaAuth = new OktaAuth({
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: environment.redirectUri,
});
const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: ':id',
    component: ProfileComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'home',
    component: ProfileComponent,
    canActivate: [OktaAuthGuard],
  }
];
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DatatableComponent,
    TenantTemplateComponent,
    TextareaComponent,
    ProfileComponent,
    UniquePipe,
    percentagePipe,
    DscrTemplateComponent,
    CustomTemplateComponent,
    NoiTemplateComponent,
    DyTemplateComponent,
    ButtonclickComponent,
    NameDPipe,
    RemoveSpacesPipe,
    AddCustomTriggerComponent,
    CustomtriggerTemplateComponent,
    BtnCellRenderer,
    SnoozeCellRenderer,
    TriggerHistoryDialogComponent,
    OtherTemplateComponent
  ],
  imports: [
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatDialogModule,
    OktaAuthModule,
    TextFieldModule,
    ClipboardModule,
    MatChipsModule,
    MatMenuModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),    // AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    NgDragDropModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatTreeModule,
    MatSidenavModule,
    FormsModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: true,
      easing: 'ease-out',
    }),
    AgGridModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: OKTA_CONFIG, useValue: { oktaAuth } },
  {
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
  },

  {
    provide: OKTA_CONFIG,
    useFactory: () => {
      return {
        oktaAuth,
        onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
          const triggerLogin = async () => {
            await oktaAuth.signInWithRedirect();
          };
          if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
            // App initialization stage
            triggerLogin();
          }
        }
      }
    }
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
