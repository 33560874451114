import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { DataTableService } from '../datatable/datatable.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UpdationServiceService } from 'src/app/services/updation-service.service';

@Component({
  selector: 'btn-cell-renderer',
  template: `<mat-slide-toggle (click)="onClick($event)" [checked]="checked"></mat-slide-toggle>`,
  styleUrls: ['./button-cell-renderer.css'],
})
export class BtnCellRenderer implements ICellRendererAngularComp {
  selectedRowId: any;
  statusCode: any;
  toggleValue: any;
  checked: any;
  rowClassRules: any = {};
  responseData: any;
  constructor(
    private toastrService: ToastrService,
    private triggerservice: DataTableService,
    private updationService: UpdationServiceService
  ) { }

  private params: any;


  onClick(e: any) {
    if(this.params.data.disabled === 'Y') {
      this.params.data.disabled = 'N';
      this.triggerservice.enableRowData(this.params.data.id).subscribe((data: any) => {
        console.log(data);
        this.updationService.updateActionsCell(data);
        this.toastrService.success('Trigger Enabled');
      }, (error:any) => {
        this.toastrService.error(error);
      })
    } else if(this.params.data.disabled === 'N') {
      this.params.data.disabled = 'Y';
      this.triggerservice.disableRowData(this.params.data.id).subscribe((data: any) => {
        console.log(data);
        this.updationService.updateActionsCell(data);
        this.toastrService.error('Trigger Disabled');
      }, (error: any) => {
        this.toastrService.error(error);
      })
    }
  }



  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (params.data.disabled === 'Y') {
      this.checked = false;
    } else {
      this.checked = true;
    }
  }

  refresh(params: ICellRendererParams) {
   return true;
  }
}