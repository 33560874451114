<div class="text-right m-2" role="button" (click)="closeForm()">
    <mat-icon>cancel</mat-icon>
</div>
<ng-container *ngIf="triggerType=='TENANT'||triggerType=='Tenant'">
    <app-tenantTemplate [triggerID]="triggerID" [dataSet]="dataSet"></app-tenantTemplate>
</ng-container>
<ng-container *ngIf="triggerType=='DSCR'">
    <app-dscr-template [triggerID]="triggerID" [masterloanidtrepp]="masterloanidtrepp" [dataSet]="dataSet"></app-dscr-template>
</ng-container>
<ng-container *ngIf="triggerType=='DebtYield'">
    <app-dy-template [triggerID]="triggerID" [masterloanidtrepp]="masterloanidtrepp" [dataSet]="dataSet"></app-dy-template>
</ng-container>
<ng-container *ngIf="triggerType=='NOI'">
    <app-noi-template [triggerID]="triggerID" [masterloanidtrepp]="masterloanidtrepp" [dataSet]="dataSet"></app-noi-template>
</ng-container>
<ng-container *ngIf="triggerType =='CUSTOM'">
    <app-customtrigger-template [triggerID]="triggerID" [loanNumber]="loanNumber" [dataSet]="dataSet"></app-customtrigger-template>
</ng-container>
<ng-container *ngIf="triggerType =='OTHER'">
    <app-other-template [triggerID]="triggerID" [dataSet]="dataSet"></app-other-template>
</ng-container>