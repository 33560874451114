import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment.dev';

@Injectable({
    providedIn: 'root'
})

export class TriggerHistoryDialogService {
    constructor(private http: HttpClient) { }
    getTriggerHistory(id: any): Observable<any>{
        let body = {};
        return this.http.get<any>(environment.SURVIELANCETRIGGER_API_URL + 
            'Events/getEventsForTriggers?triggerId=' + id, {observe: 'response'});
    }

    addTriggerDescription(data: any): Observable<any>{
        return this.http.post<any>(environment.SURVIELANCETRIGGER_API_URL + 
            'Events/postEventsForTriggers', data );
    }

    snoozeTrigger(days: any, id: any): Observable<any>{
        let body = {};
        return this.http.patch<any>(environment.SURVIELANCETRIGGER_API_URL +
            'triggerSettings/snoozeTriggerWithDays?triggerId=' + id + '&days=' + days, body, { observe: 'response'});
    }

    unsnoozeTrigger(id: any): Observable<any>{
        let body = {};
        return this.http.patch<any>(environment.SURVIELANCETRIGGER_API_URL + 
            'triggerSettings/unsnoozeTrigger?triggerId=' + id, {observe: 'response'});
    }
 

 
}