import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class DataTableService {
    constructor(private http: HttpClient) { }
    getTriggersTableData(): Observable<any[]> {
        return this.http.get<any[]>(environment.SURVIELANCETRIGGER_API_URL + 'triggerSettings/getDetailedTriggers')
          .pipe(
            catchError(error => {
              console.error('Error fetching data:', error);
              return of([]); // Return an empty array in case of error
            })
          );
      }

    getAllCMBSData(): Observable<any> {
        return this.http.get(environment.SURVIELANCETRIGGER_API_ALLCMBS)
            .pipe(
                catchError(error => {
                    console.error('Error fetching data:', error);
                    return of([]); // Return an empty array in case of error
                })
            );
    }

    getAllCLOData(): Observable<any>{
        return this.http.get(environment.SURVIELANCETRIGGER_API_ALLCLO)
            .pipe(
                catchError(error => {
                    console.error('Error fetching data:', error);
                    return of([]); // Return an empty array in case of error
                })
            );
    }

    getGlobalTriggersData(): Observable<any>{
        return this.http.get(environment.SURVIELANCETRIGGER_API_GLOBALTRIGGERS)
            .pipe(
                catchError(error => {
                    console.error('Error fetching data:', error);
                    return of([]); // Return an empty array in case of error
                })
            );
    }

    getMyTriggersData(): Observable<any>{
        return this.http.get(environment.SURVIELANCETRIGGER_API_MYTRIGGERS)
            .pipe(
                catchError(error => {
                    console.error('Error fetching data:', error);
                    return of([]); // Return an empty array in case of error
                })
            );
    }

    disableRowData(id: any): Observable<any>{
        let body = {};
        return this.http.patch<any>(environment.SURVIELANCETRIGGER_API_URL +
            'TriggerSettings/disableTrigger?triggerId=' + id, body, { observe: 'response' });
    }
    enableRowData(id: any): Observable<any>{
        let body = {};
        return this.http.patch<any>(environment.SURVIELANCETRIGGER_API_URL +
            'TriggerSettings/enableTrigger?triggerId=' + id, body, { observe: 'response' });
    }
}