<div class="text-right m-2" role="button" (click)="closeForm()">
    <mat-icon>cancel</mat-icon>
  </div>
<div class="container-fluid">
    <div class="text-center">
      <h1>Add Custom Trigger</h1>
    </div>
      <div class="container flex flex-wrap p-4 mx-auto">
          <label for="loan" class="col-sm-4 col-form-label">Loan:</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" id="droppable" #userCheck
            name="triggerAssignedTo" ngDefaultControl
            [ngModel]="userData.loanNumber" (ngModelChange)="userData.loanNumber = $event">
            
        </div>
      </div>
  
      <div class="container flex flex-wrap p-4 mx-auto">
        <label for="notificationDate" class="col-sm-4 col-form-label">Notification Date:</label>
        <div class="col-sm-7">
          <mat-form-field appearance="fill" [style.width.px]=327>
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" name="date"
            [ngModel]="userData.triggerDateValue"
                        (ngModelChange)="onDate($event)" [ngModelOptions]="{standalone: true}">

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [ngModel]="userData.triggerDateValue" name="datematpicker" ngDefaultControl 
            (ngModelChange)="userData.triggerDateValue = $event" (selectedChanged)="onDate($event)"
            [ngModelOptions]="{standalone: true}"></mat-datepicker>
          </mat-form-field>
      </div>
    </div>
  
    <div class="container flex flex-wrap p-4 mx-auto">
      <label for="lastFiredValue" class="col-sm-4 col-form-label">Description :</label>
      <div class="col-sm-7">
       <textarea class="form-control text-box-height" ngDefaultControl [value]="userData.triggerDescription"
       (input)="onValueChange($event)"></textarea>
    </div>
  </div>
  
  <div class="text-center">
    <button class="submitButton" mat-flat-button color="primary" #button
    [title]="button.disabled?'Kindly save the form to enable submit':'click to submit'"
                    (click)="showSuccess();">Save</button>
  </div>
  
    
        
  
  