import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'percentagePipe',
})
export class percentagePipe implements PipeTransform {
  transform(value: string): string {
    let newVal = ''; 
    if(value && value.indexOf('%') != -1) {
      newVal = value.replace(/[^\w\s.]/gi, '');
      return newVal;
      
    }
    //newVal = value.replace(/[^\w\s.]/gi, '');
    return newVal;
  }
}