import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IHeaderGroupParams } from 'ag-grid-community';

@Component({
  selector: 'app-buttonclick',
  templateUrl: './buttonclick.component.html',
 styleUrls: ['./buttonclick.component.css']
})
export class ButtonclickComponent implements  ICellRendererAngularComp {
  agInit(params: ICellRendererParams<any, any>): void {
    throw new Error('Method not implemented.');
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    throw new Error('Method not implemented.');
  }

 }

