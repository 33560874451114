import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpErrorResponse, HttpClient } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { RouterModule, Routes, Router } from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token: any;
  httpReq: any;
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private _route: Router, private http: HttpClient) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.token = JSON.parse(localStorage.getItem('okta-token-storage')!).idToken.idToken;
    if(this.token) {
      request = request.clone({ setHeaders: { 'Authorization': `Bearer ${this.token}` } });
      return next.handle(request)
    } 
    else {
      return next.handle(request)
      .pipe(
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              let errorMsg = '';
              if(error.status === 401) {
                alert('token expired');
               
              }

              return throwError(errorMsg);
            })
      )
    }
  
 }
}


