<mat-drawer-container>
  <mat-drawer-content [ngClass]="{'background-black': applyBackground, 'background-white': !applyBackground}">
      <div class="toggle-view" >
        Light <mat-slide-toggle (toggleChange)="changeToggle(theme)"></mat-slide-toggle> Dark 
        <span class="button-row">
          <span class="material-icons-outlined cursor-pointer button-save" (click)="saveState()" matTooltip="Save">bookmark_add </span>
          <span class="material-icons-outlined cursor-pointer button-restore" (click)="restoreState()" matTooltip="Restore">bookmark_border</span>
          <span class="material-icons-outlined cursor-pointer button-reset" (click)="resetState()" matTooltip="Reset"> bookmark_remove </span>
        </span>
      </div>
    
    
    <br>
    <mat-drawer style="width:700px;" #sideNav mode="side" position="end" [opened]="isShowing">
      <app-custom-template [triggerID]="triggerID" [triggersource]="triggerSource"
        (closeSideNav)="isShowing = !isShowing"></app-custom-template>
    </mat-drawer>
    <mat-drawer style="width:700px;" #sideNav mode="side" position="end" [opened]="isCustomTriggerShow">
      <app-clo-template [loanNumber]="loanNumber" [loanKey]="loanKey"
        (closeSideNav)="isCustomTriggerShow = !isCustomTriggerShow"></app-clo-template>
    </mat-drawer>

    <br>
    <ag-grid-angular style="width:100%; height:830px;" [class]="theme" [columnDefs]="columnDefs" [animateRows]="true"
      [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="100" [rowData]="rowData"
      [rowClassRules]="rowClassRules" (gridReady)="onGridReady($event)" rowSelection="multiple"
      (rowClicked)="onRowClick($event)" [getContextMenuItems]="getContextMenuItems" [suppressContextMenu]="false"
      [preventDefaultOnContextMenu]="true"></ag-grid-angular>


  </mat-drawer-content>
</mat-drawer-container>
<br>
<br>