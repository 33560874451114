import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { TriggerTemplateService } from 'src/app/services/trigger-template.service';
import { UpdationServiceService } from 'src/app/services/updation-service.service';
import { TriggerHistoryDialogComponent } from '../trigger-history-dialog/trigger-history-dialog.component';
import { TriggerHistoryDialogService } from '../trigger-history-dialog/trigger-history-dialog.service';

@Component({
  selector: 'app-other-template',
  templateUrl: './other-template.component.html',
  styleUrls: ['./other-template.component.css']
})
export class OtherTemplateComponent implements OnInit {
  splittedData: any;
  triggerDescription: any;
  resSplit: any;
  triggerAssignedChecker: any;
  all: any;
  custom: boolean = false;
  updateTriggerValue: any;
  masterloadidTrepp: any;
  currentDate: string = '';
  isDisabled30: boolean = false;
  isDisabled90: boolean = false;
  @Output() closeSideNav = new EventEmitter();
  @Output() trackIndex = new EventEmitter();
  @ViewChild('autosize') autosize!: CdkTextareaAutosize;
  resObject: any;
  myDate = new Date();
  @Input() set triggerID(id: any) {
    this.userData.triggerID = id;
  }
  @Input() dataSet: any;
  public content!: string;
  userData = {
    loanKey: '',
    triggerID: '',
    triggerType: '',
    loanNumber: '',
    bloombergID: '',
    triggerValue: '',
    triggerDescription: '',
    highlight: [''],
  };

  constructor(private toastrService: ToastrService, private historyService: TriggerHistoryDialogService, private _ngZone: NgZone,
    public dialog: MatDialog, private updationService: UpdationServiceService, private triggerTemplateService: TriggerTemplateService) 
    { this.content = this.userData.triggerDescription; }

  ngOnInit(): void {
    let element: HTMLElement = document.getElementsByClassName('draggable') as unknown as HTMLElement;
    element.click;
  }

  findNumber() {
    this.splittedData = this.triggerDescription.split(/(\b(?:0?[1-9]|1[0-2])[\/\-](?:0?[1-9]|[12]\d|3[01])[\/\-](?:\d{4})\b)/gm);
    this.resSplit = this.splittedData.reduce(function (s: any, a: any) {
      s.push({ text: a, enableDrag: false });
      return s;
    }, []);
    this.resSplit.forEach((item: any) => {
      if (item.text.match(/^\d/)) {
        item.enableDrag = true;
      }
    });
  }

  ngOnChanges(): void {
    this.userData.triggerDescription = this.dataSet.loanTriggerSettings;
    this.userData.loanKey = this.dataSet.loanKey;
    this.userData.triggerType = this.dataSet.triggerType;
    this.userData.loanNumber = this.dataSet.loanNumber; this.userData.bloombergID = this.dataSet.bloombergID;
    this.userData.triggerValue = this.dataSet.triggerValue;
    this.triggerAssignedChecker = this.dataSet.triggerValue;
    this.userData.highlight = this.dataSet.triggerExtractions;
    this.all = this.dataSet.triggerExtractions;
    this.custom = false;
    this.updateTriggerValue = this.dataSet.triggerDateValue;
    this.triggerDescription = this.dataSet.triggerDescription;
    this.findNumber();
    this.masterloadidTrepp = this.dataSet.masterloanidtrepp;
    if (this.dataSet.daySnooze != null) {
      this.currentDate = formatDate(this.dataSet.daySnooze, 'MM-dd-YYYY', 'en');
      this.isDisabled30 = true;
      this.isDisabled90 = true;
    } else {
      this.currentDate = '';
      this.isDisabled30 = false;
      this.isDisabled90 = false;
    }
  }

  dropIntoInput(ev: any) {
    this.updateTriggerValue = ev.dragData;
  }

  redirectToUrl(id: any) {
    window.open('https://propapi.rialtocapital.com/api/Redirection/RedirectToTrepp?masterPropertyTreppId=' + this.masterloadidTrepp, '_blank');
  }

  toggleClick() {
    this.custom = !this.custom;
  }

  openDialog(): void {
    this.historyService.getTriggerHistory(this.dataSet.id).subscribe((res: any) => {
      const triggerHistory = res.body;
      this.dialog.open(TriggerHistoryDialogComponent, {
        data: { data: triggerHistory }
      });
    })
  }
  noReturnPredicate() {
    return true;
  }
  closeForm(): void {
    this.closeSideNav.emit();
  }
  indexTrack(): void {
    this.trackIndex.emit(this.patchResponse);
  }
  showError(): void {
    this.toastrService.error(
      'Please Submit your Changes before Closing the Form'
    );
  }
  patchResponse: any;
  showSuccess() {
    var x = this.dataSet;
    this.toastrService.success('Your Changes have been Sent for Review!');
    this.triggerTemplateService.saveUpateTrigger(x).subscribe(
      (data) => {
        this.toastrService.success('Trigger Updated!');
        this.patchResponse = data;
        this.updationService.setProduct(data);
      }
    );
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  textAreaAdjust(element: any) {
    element.style.height = '1px';
    element.style.height = 25 + element.scrollHeight + 'px';
  }
  public onDate(event: any) {
    this.dataSet.expirationDate = event;
    //this.getData(this.roomsFilter.date);
  }
  calculateDiff(dateSent: any) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    let days = Math.floor((currentDate.getTime() - dateSent.getTime()) / 1000 / 60 / 60 / 24);
    this.resObject.snoozeDaysLeft = days;
  }

  snooze30(days: any) {
    this.toastrService.info('Saving snooze..');
    this.historyService.snoozeTrigger(days, this.dataSet.id).subscribe((res: any) => {
      this.resObject = res.body;
      if (res) {
        this.isDisabled30 = true;
        this.currentDate = formatDate(this.myDate, 'MM-dd-YYYY', 'en');
        let snoozedDate = formatDate(res.body.daySnooze, 'MM-dd-YYYY', 'en');
        this.calculateDiff(snoozedDate);
        this.toastrService.success('Trigger Snoozed 30 days');
        this.updationService.setDY(this.resObject);
      }
    }, (error: any) => {
      this.toastrService.error(error);
    })
  }

  snooze90(days: any) {
    this.toastrService.info('Saving snooze..');
    this.historyService.snoozeTrigger(days, this.dataSet.id).subscribe((res: any) => {
      this.resObject = res.body;
      if (res) {
        this.isDisabled90 = true;
        this.currentDate = formatDate(this.myDate, 'MM-dd-YYYY', 'en');
        let snoozedDate = formatDate(res.body.daySnooze, 'MM-dd-YYYY', 'en');
        this.calculateDiff(snoozedDate);
        this.toastrService.success('Trigger Snoozed 90 days');
        this.updationService.setDY(this.resObject);
      }

    }, (error: any) => {
      this.toastrService.error(error);
    })
  }

  unsnoozeTrigger() {
    this.toastrService.info('Unsnoozing trigger..');
    this.historyService.unsnoozeTrigger(this.dataSet.id).subscribe((res: any) => {
      this.isDisabled30 = false;
      this.isDisabled90 = false;
      this.currentDate = '';
      this.toastrService.success('Trigger Unsnoozed');
      this.updationService.setDY(res);

    }, (error: any) => {
      this.toastrService.error(error);
    })
  }

}
