import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'snooze-cell-renderer',
  template: `<span class="material-icons">snooze</span>`,
  styleUrls: [],
  providers: [DatePipe]
})
export class SnoozeCellRenderer implements ICellRendererAngularComp {
    myDate = new Date();
    daysLeft: any;
    currentDate: string;
    snoozeTime: any;
  constructor(private datePipe: DatePipe
   
  ) { 
    let myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.currentDate = formatDate(this.myDate, 'MM-dd-YYYY', 'en');
  }

//   private params: any;

  calculateDiff(dateSent: any, params: any){
      //this.params = params;
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    this.daysLeft = Math.floor((currentDate.getTime() - dateSent.getTime()) / 1000 / 60 / 60 / 24);
    params.data.daySnooze = this.daysLeft + '' + 'days left';
    console.log(params);
  }

  agInit(params: ICellRendererParams): void {
      //this.params = params;
   this.snoozeTime = params.data.daySnooze;
   //console.log(this.snoozeTime);
   let snoozedDate = formatDate(this.snoozeTime, 'MM-dd-YYYY', 'en');
   console.log(snoozedDate);
   if(this.snoozeTime != null) {
    this.calculateDiff(snoozedDate, params);
   }
  }

  refresh(params: ICellRendererParams) {
   return true;
  }
}