import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TriggerTemplateService {

  constructor(private http: HttpClient) { }
  getIndividualLoanData(id: any): Observable<any> {
    return this.http.get<any>(environment.SURVIELANCETRIGGER_API_URL + 
      'triggerSettings/getIndividaulFlatLoanWithTriggerId?triggerId=' + id);
  }

  saveCustomTrigger(data: any): Observable<any> {
    return this.http.post<any>(environment.SURVIELANCETRIGGER_API_URL + 
      'triggerSettings/postCustomCLOTrigger', data);
  }

  updateCustomTrigger(data: any): Observable<any> {
    return this.http.patch<any>(environment.SURVIELANCETRIGGER_API_URL + 
      'triggerSettings/updateCustomCLOTrigger', data);
  }

  saveUpateTrigger(data: any): Observable<any> {
    return this.http.patch<any>(environment.SURVIELANCETRIGGER_API_URL + 
      'triggerSettings/updateFlatTrigger', data)
  }
}

