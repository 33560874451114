<header class="w-full text-gray-700 bg-white border-t border-gray-100 shadow-sm body-font">
    <div class="container flex flex-col flex-wrap items-center p-0.5 mx-auto">
        <div
            class="flex items-center order-first mt-2 font-medium text-gray-900 lg:order-none title-font lg:items-center lg:justify-center md:mb-0">
            <h2 class="text-xl text-gray-900 font-bold">Trigger History</h2>
        </div>
    </div>
</header>

<div class="container flex flex-wrap p-4 mx-auto">
    <mat-form-field class="example-full-width" [style.width.px]=327>
        <mat-label>Add a Note</mat-label>
        <textarea [style.height.px]=80 matInput [(ngModel)]="notes"></textarea>
    </mat-form-field>
    <span class="button-note"><button class="btn btn-primary right-10" (click)="AddNote(notes)">Add
            Note</button></span>
</div>
<div class="text-center">{{message}}</div>
<div class="flex-1 bg-white rounded-lg shadow-xl mt-4">
    <div class="relative px-4">
        <div class="absolute h-full border border-dashed border-opacity-20 border-secondary"></div>
        <div *ngFor="let data of DataArray; let i = index;">
            <div class="flex items-center w-full my-6 -ml-1.5">
                <div class="w-1/12 z-10">
                    <div *ngIf="data.eventType === 'TR_SNOOZED'" class="w-3.5 h-3.5 round_snooze rounded-full"></div>
                    <div *ngIf="data.eventType === 'TR_CREATED'" class="w-3.5 h-3.5 round_created rounded-full"></div>
                    <div *ngIf="data.eventType === 'TR_ENABLED'" class="w-3.5 h-3.5 round_enabled rounded-full"></div>
                    <div *ngIf="data.eventType === 'TR_UPDATED'" class="w-3.5 h-3.5 round_updated rounded-full"></div>
                    <div *ngIf="data.eventType === 'TR_DISABLED'" class="w-3.5 h-3.5 round_disabled rounded-full"></div>
                    <div *ngIf="data.eventType === 'TR_NOTE'" class="w-3.5 h-3.5 round_note rounded-full"></div>
                    <div *ngIf="data.eventType === 'TR_UNSNOOZED'" class="w-3.5 h-3.5 round_unsnoozed rounded-full"></div>
                    <div *ngIf="data.eventType === 'TR_FIRED'" class="w-3.5 h-3.5 round_fired rounded-full"></div>
                </div>
                <div class="w-11/12">
                    <p class="text-sm">{{ data.eventDescription }}</p>
                    <p class="text-sm">{{ data.userEmail==null? ' ': data.userEmail }}</p>
                    <p class="text-xs text-gray-500">{{ data.auditCreateDateTime | date }}</p>
                </div>
            </div>