import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TriggerTemplateService } from 'src/app/services/trigger-template.service';

@Component({
  selector: 'app-custom-template',
  templateUrl: './custom-template.component.html',
  styleUrls: ['./custom-template.component.css']
})
export class CustomTemplateComponent implements OnInit, OnChanges {
  @Input() triggerID!: any;
  @Input() masterloanidtrepp!: any;
  @Input() loanNumber!: any;
  @Input() triggersource!: any;
  @Output() closeSideNav = new EventEmitter();
  constructor(private triggerTemplateService: TriggerTemplateService) { }
  triggerType!: string;
  triggerSource!: string;
  dataSet: any;
  loanKey!: number;
  ngOnInit(): void {
  }
  closeForm(): void {
    this.closeSideNav.emit();
  }
  ngOnChanges(): void {
    if (this.triggerID != null && !Number.isNaN(this.triggerID) && this.triggersource != "TreppAuto") {
      this.triggerTemplateService.getIndividualLoanData(this.triggerID).subscribe((data) => {
        this.dataSet = data;
        this.triggerType = data.triggerType;
        this.triggerSource = data.triggerSource;
      }
      )
    }
  }
}
