export const environment = {
    production: false,
    SURVIELANCETRIGGER_API_URL: 'https://surveillanceapi.rialtocapital.com/',
    SURVIELANCETRIGGER_API_MYTRIGGERS: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getDetailedTriggers',
    SURVIELANCETRIGGER_API_ALLCMBS: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getCMBSUserFlatTriggers',
    SURVIELANCETRIGGER_API_ALLCLO: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getCLOUserFlatTriggers',
    SURVIELANCETRIGGER_API_GLOBALTRIGGERS: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getGlobalFlatTriggers',
    issuer: 'https://rialtocapital.okta.com/oauth2/default',
    clientId: '0oaprokis3MdjAoBT357',
    redirectUri: 'https://triggers.rialtocapital.com/login/callback',
    //redirectUri: 'http://localhost:4100/login/callback',
};