import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UpdationServiceService } from 'src/app/services/updation-service.service';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DataTableService } from '../datatable/datatable.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  theme = 'ag-theme-balham'
  @Input() name!: Observable<string>;
  globalMenuName!: string;
  value!: any;
  constructor(private toastrService: ToastrService,
    private updationServiceService: UpdationServiceService,
    private http: HttpClient, private triggerService: DataTableService,) { }

  ngOnInit(): void {

  }
  shareOperations() {
    this.toastrService.info('The Link to the test site is copied to your Clipboard!!');
  }

  myTriggers() {
    this.updationServiceService.startLoading();
    this.updationServiceService.globalMenuName = "myTrigger";
    this.triggerService.getMyTriggersData().subscribe(data => {
      this.updationServiceService.resetAgGridForAllTrigger(data);
    });
  }

  allCMBS() {
    this.updationServiceService.startLoading();
    this.updationServiceService.globalMenuName = "allCMBS";
    this.triggerService.getAllCMBSData().subscribe(data => {
      this.updationServiceService.resetAgGridForAllCMBS(data);
    });
  }

  allCLO() {
    this.updationServiceService.startLoading();
    this.updationServiceService.globalMenuName = "allCLO";
    this.triggerService.getAllCLOData().subscribe(data => {
      this.updationServiceService.resetAgGridForAllCLO(data);
    });
  }

  globalTriggers() {
    this.updationServiceService.startLoading();
    this.updationServiceService.globalMenuName = "globalTrigger";
    this.triggerService.getGlobalTriggersData().subscribe(data => {
      this.updationServiceService.resetAgGridForGlobalTriggers(data);
    });
  }

  switchGridColor() {
    this.theme = 'ag-theme-baham-dark';
  }
}
