import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgZone, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';
import 'jqueryui';
import { HttpClient } from '@angular/common/http';
import { UpdationServiceService } from 'src/app/services/updation-service.service';
import { TriggerHistoryDialogService } from '../trigger-history-dialog/trigger-history-dialog.service';
import {TriggerHistoryDialogComponent } from '../trigger-history-dialog/trigger-history-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TriggerTemplateService } from 'src/app/services/trigger-template.service';
export interface DialogData {
  triggerData: Object
}
@Component({
  selector: 'app-noi-template',
  templateUrl: './noi-template.component.html',
  styleUrls: ['./noi-template.component.css'],
  providers: [DatePipe]
})
export class NoiTemplateComponent implements OnInit {
  dropped=0;
  description: any;
  all: string[] = [];
  even: string[] = [];
  originalIndex = 9;
  manualInputEnabled:boolean=true;
  triggerAssignedChecker!: string;
  stringTemp!:string;
  currentDate: any;
  isDisabled90: any;
  isDisabled30: any;
  resObject: any;
  splittedData: any;
  resSplit: any;
  updateTriggerValue: any;
  triggerDescription: any;
  triggerExt: any;
  masterloadidTrepp: any;

  @Input() set triggerID(id: any) {
    this.userData.triggerID = id;
  }
  @Input() set loanKey(id: any) {
    this.userData.loanKey = id;
  }
  @Input() set triggerType(id: any) {
    this.userData.triggerType = id;
  }
  @Input() set loanNumber(id: any) {
    this.userData.loanNumber = id;
  }
  @Input() set bloombergID(id: any) {
    this.userData.bloombergID = id;
  }
  @Input() set triggerValue(id: any) {
    this.userData.triggerValue = id;
    this.triggerAssignedChecker = id;
  }

  @Input() set highlighted(text: string[]) {
    this.userData.highlight = text;
    this.all=text;
  }
  @Output() closeSideNav = new EventEmitter();
  userData = {
    loanKey:'',
    triggerID: '',
    triggerType: '',
    loanNumber:'',
    bloombergID:'',
    triggerValue: '',
    triggerDescription: '',
    highlight: [''],
  };
  public content!: string;
  myDate = new Date();
  constructor(private toastrService: ToastrService, private _ngZone: NgZone, private http:HttpClient,
    private updationService: UpdationServiceService, public dialog: MatDialog, private triggerTemplateService: TriggerTemplateService,
    private historyService: TriggerHistoryDialogService,private datePipe: DatePipe) { 
      let myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.content = this.userData.triggerDescription;
  }
public query=this.userData.highlight;
  @ViewChild('autosize') autosize!: CdkTextareaAutosize;
  arr!:string[];
  trackTriggerDescription:boolean=true;
  detailData:any=[];
  dateExp:any;
  dateSnooze:any;
  timeExp:string="";
  @Input() set custom_bool(custom:boolean){
    this.custom=custom;
  }
  custom:boolean=false;
  @Input() dataSet: any;

  ngOnInit(): void {
    let element: HTMLElement = document.getElementsByClassName('draggable') as unknown as HTMLElement;
    element.click;
  }

 findNumber() {
  this.splittedData = this.triggerDescription.split(/(\$\d{1,3}(?:(?:,\d{3})*)?(?:\.\d{2})?)/g);
  this.resSplit = this.splittedData.reduce(function (s: any, a: any) {
    s.push({ text: a, enableDrag: false });
    return s;
  }, []);
  
  this.resSplit.forEach((item: any) => {
    if (item.text.match(/^\$/)) {
      item.enableDrag = true;
    }
  });
} 
  ngOnChanges(): void {
    this.userData.triggerDescription = this.dataSet.loanTriggerSettings;
    this.description = this.dataSet.triggerDescription;
    this.masterloadidTrepp = this.dataSet.masterloanidtrepp;
    this.updateTriggerValue = this.dataSet.triggerValue;
    this.triggerExt = this.dataSet.triggerExtractions;
    this.triggerDescription = this.dataSet.triggerDescription;
    this.findNumber();
    this.description = this.dataSet.triggerDescription;
    if (this.dataSet.daySnooze != null) {
      this.currentDate = formatDate(this.dataSet.daySnooze, 'MM-dd-YYYY', 'en');
      this.isDisabled30 = true;
      this.isDisabled90 = true;
    } else {
      this.currentDate = '';
      this.isDisabled30 = false;
      this.isDisabled90 = false;
    }
  }

  redirectToUrl(id: any) {
    window.open('https://propapi.rialtocapital.com/api/Redirection/RedirectToTrepp?masterPropertyTreppId=' + this.masterloadidTrepp, '_blank');
  }
  noReturnPredicate() {
    return true;
  }
  closeForm(): void {
    this.closeSideNav.emit();
  }
  showError() {
    this.toastrService.error(
      'Please Submit your Changes before Closing the Form'
    );
  }
  dropIntoInput(ev: any) {
    if(ev.dragData && ev.dragData.indexOf('$') != -1) {
      this.updateTriggerValue = ev.dragData.replace(/[^\w\s.]/gi, '');
    }
  }

 showSuccess() {
    
    var x=this.dataSet;
   x.triggerValue=parseFloat(x.triggerValue);
   this.toastrService.success('Your Changes have been Sent for Review!');
   this.triggerTemplateService.saveUpateTrigger(x).subscribe(
    (data)=>{
      this.toastrService.success('Trigger Updated!');
      console.log(data);
      this.updationService.setNOI(data);
    }
  );
    
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  textAreaAdjust(element: any) {
    element.style.height = '1px';
    element.style.height = 25 + element.scrollHeight + 'px';
  }

  openDialog(): void {
    this.historyService.getTriggerHistory(this.dataSet.id).subscribe((res: any) => {
      const triggerHistory = res.body;
      this.dialog.open(TriggerHistoryDialogComponent, {
        // height: '600px',
        // width: '600px',
        data: {data: triggerHistory}
      });
    })
  }

  calculateDiff(dateSent: any){
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    let days = Math.floor((currentDate.getTime() - dateSent.getTime()) / 1000 / 60 / 60 / 24);
    this.resObject.daySnooze = days;
  }

  snooze30(days: any) {
    this.toastrService.info('Saving snooze..');
    this.historyService.snoozeTrigger(days, this.dataSet.id).subscribe((res: any) => {
      this.resObject = res.body;
      if (res) {
        this.isDisabled30 = true;
        this.currentDate = formatDate(this.myDate, 'MM-dd-YYYY', 'en');
        let snoozedDate = formatDate(res.body.daySnooze, 'MM-dd-YYYY', 'en');
        this.calculateDiff(snoozedDate);
        this.toastrService.success('Trigger Snoozed 30 days');
        this.updationService.setNOI(this.resObject); 
      }

    }, (error: any) => {
      this.toastrService.error(error);
    })
  }

  snooze90(days: any) {
    this.toastrService.info('Saving snooze..');
    this.historyService.snoozeTrigger(days, this.dataSet.id).subscribe((res: any) => {
      this.resObject = res.body;
      if (res) {
        this.isDisabled90 = true;
        this.currentDate = formatDate(this.myDate, 'MM-dd-YYYY', 'en');
        let snoozedDate = formatDate(res.body.daySnooze, 'MM-dd-YYYY', 'en');
        this.calculateDiff(snoozedDate);
        this.toastrService.success('Trigger Snoozed 90 days');
        this.updationService.setNOI(this.resObject); 
      }

    }, (error: any) => {
      this.toastrService.error(error);
    })
  }

  unsnoozeTrigger() {
    this.toastrService.info('Unsnoozing trigger..');
    this.historyService.unsnoozeTrigger(this.dataSet.id).subscribe((res: any) => {
      this.isDisabled30 = false;
      this.isDisabled90 = false;
      this.currentDate = '';
      this.toastrService.success('Trigger Unsnoozed');
      this.updationService.setNOI(res);
    }, (error: any) => {
      this.toastrService.error(error);
    })
  }
}
