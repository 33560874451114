/* This file can be replaced during build by using the `fileReplacements` array.
   `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
   The list of file replacements can be found in `angular.json`.  */

export const environment = {
  production: false,
  SURVIELANCETRIGGER_API_URL: 'https://surveillanceapi.rialtocapital.com/',
  SURVIELANCETRIGGER_API_MYTRIGGERS: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getDetailedTriggers',
  SURVIELANCETRIGGER_API_ALLCMBS: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getCMBSUserFlatTriggers',
  SURVIELANCETRIGGER_API_ALLCLO: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getCLOUserFlatTriggers',
  SURVIELANCETRIGGER_API_GLOBALTRIGGERS: 'https://surveillanceapi.rialtocapital.com/triggerSettings/getGlobalFlatTriggers',
  issuer: 'https://rialtocapital.okta.com/oauth2/default',
  clientId: '0oaprokis3MdjAoBT357',
  redirectUri: 'https://triggers.rialtocapital.com/login/callback',
  //redirectUri: 'http://localhost:4100/login/callback',
};
    /*
* In development mode, to ignore zone related error stack frames such as
* `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
* import the following file, but please comment it out in production mode
* because it will have performance impact when throw error
*/
/* import 'zone.js/dist/zone-error';  */ /* Included with Angular CLI. */
