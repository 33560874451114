<mat-toolbar color="primary">
  <div class="header-center">
    <img class="responsive" src="../../../assets/Rialto-Logo.png">
    <h1 class="title-center">Loan Triggers</h1>
  </div>
  <span class="example-spacer"></span>
  <button mat-icon-button class="example-icon" [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>Hi! {{ name | async | nameDPipe}}</button>
    <button mat-menu-item (click)="myTriggers()">My Triggers</button>
    <button mat-menu-item (click)="allCMBS()">All CMBS</button>
    <button mat-menu-item (click)="allCLO()">All ClO</button>
    <button mat-menu-item (click)="globalTriggers()">Global Trigger</button>
  </mat-menu>
</mat-toolbar>