import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameDPipe'
})
export class NameDPipe implements PipeTransform {

  transform(value: string|null): string|null {
   if(value){const words = value.split(' '); // split the string into an array of words
    const firstName = words[0]; // extract the first word
    const secondWord = words[1] || ''; // extract the second word or set an empty string as the default
     const firstLetterOfSecondWord = secondWord.charAt(0); // extract the first letter of the second word 
     return `${firstName} ${firstLetterOfSecondWord}.`; // return the formatted string
     }
     else{
       return null;
     }
    }
}
